import React from "react"

import { Layout } from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage: React.VFC = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>404 File Not Found</h1>
    <p>ページが見つかりません</p>
  </Layout>
)

export default NotFoundPage
